<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <card>
          <h4 slot="header" class="card-title">{{$t('DatosCliente')}}</h4>
            <div class="row mb-5">
              <div class="col-12">
                <p class="mt-0 mb-2 text-info">{{$t("Conformidad_RGPD")}}</p>
                <base-checkbox disabled checked> {{$t("RGPD_TEXT")}}</base-checkbox>
              </div>
              <div class="col-md-6 pl-3">
                <div>
                  <p class="mt-0 mb-2 text-info">{{$t("Datos_Personales")}}</p>
                  <hr class="border-top mt-0 border-info">
                  <div class="row px-3">
                    <div class="col-4 col-4 py-2 text-secondary font16 font-weight-bold">{{$t("Contacto")}}</div>
                    <div class="col-8 d-flex align-items-center font14">{{profile.client.name}} {{profile.client.lastname}}</div>
                 
                    <div class="col-4 col-4 border-top py-2 text-secondary font16 font-weight-bold">{{$t("document")}}</div>
                    <div class="col-8 border-top d-flex align-items-center font14">{{profile.client.document}}</div>                                  
                
                    <div class="col-4 col-4 border-top py-2 text-secondary font16 font-weight-bold">{{$t("phone")}}</div>
                    <div class="col-8 border-top font14 ">{{profile.client.phone}}</div>
                  </div>
                </div>
          
                <div class="mt-4">
                  <p class="mt-0 mb-2 text-info">{{$t("DatosDomicilio")}}</p>
                  <hr class=" mt-0 ">
                  <div  class="row px-3">
                    <div class="col-4 col-4  py-2 text-secondary font16 font-weight-bold">{{$t("Direccion")}}</div>
                    <div class="col-8 d-flex align-items-center font14">{{profile.client.address}}<span class="mr-2" v-if="profile.client.zipcode">, {{profile.client.zipcode}} </span> {{profile.client.city}}</div>
                    <div class="col-4 col-4 border-top  py-2 text-secondary font16 font-weight-bold">{{$t("Estado")}}</div>
                    <div class="col-8 border-top d-flex align-items-center font14">{{profile.client.state}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="">
                  <p class="mt-0 mb-2 text-info">{{$t("Datos_Usr")}}</p>
                  <hr class="border-top mt-0 border-info">
                   <div class="row">
                      <div class="col-4 col-4  py-2 text-secondary font16 font-weight-bold">{{$t("Nombre")}}</div>
                      <div class="col-8  d-flex align-items-center font14">{{profile.name}}</div>
                      <div class="col-4 col-4 border-top py-2 text-secondary font16 font-weight-bold">{{$t("user_position")}}</div>
                      <div class="col-8 border-top d-flex align-items-center font14">{{profile.client_user.position}}</div>
                      <div class="col-4 col-4 border-top py-2 text-secondary font16 font-weight-bold">{{$t("Email")}}</div>
                      <div class="col-8 border-top d-flex align-items-center font14">{{profile.email}}</div>
                  </div>                  
                </div>
              </div>
               <div class="col-12">
                 <div class="d-flex justify-content-end">
                      <button class="btn btn-info btn-fill" @click="onSubmit">
                        <i class="fas fa-save mr-2"></i>
                          {{$t("Actualizar")}}
                      </button>
                  </div>
               </div>
            </div>
        </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created(){
    this.onClienteProfile();
  },
  data(){
    return {
      profile : {
        client : {}
      }
    }
  },
  methods : {
    onClienteProfile(){
        this.$store.dispatch('isLoadingOn');
         this.$axios("/client_profile")
          .then((response) => {    
            this.profile = response.data;     
            this.$store.dispatch('isLoadingOff');
          })
          .catch(()=>{
            this.$store.dispatch('isLoadingOff');
          })
    },
    onSubmit(){
       this.$store.dispatch('isLoadingOn');
         this.$axios.post("/client_profile",{})
          .then(() => {    
           this.$store.dispatch('isLoadingOff');
           this.$dialog.alert({
                title: this.$t("DatosCliente"),
                body: this.$t("DatosCliente"),
            });
          })
          .catch(()=>{
            this.$store.dispatch('isLoadingOff');
          })
    }
  }
}
</script>

<style >
 
</style>